import axios from "axios";
const canvas = document.getElementById("canvas");
const root = document.getElementById("root");
const formUpload = document.getElementById("formUpload");
import {API_URL} from "../environment/environment";
import {DocumentRecognition} from "../services/documentRecognition";
export class Canvas {
  constructor(document) {
    if (document) {
      this.setBackground(document.width, document.height, document.id);
      this.setPoints(document.finalModelEntityList);
    }
  }

  setPoints(points) {
    let x;
    let y;
    let width;
    let height;
    var i;

    for (i = 0; i < points.length; i++) {
      if (points.hasOwnProperty(i)) {
        console.log(points[i]);
        points[i].vertices.forEach((point, j) => {
          if (j === 0) {
            x = point.x;
            y = point.y;
          }
          //prawy gorny
          if (j === 1) {
            width = point.x - x;
          }
          //lewy dolny
          if (j === 2) {
            height = point.y - y;
          }
        });

        if (width > 0 && height > 0) {
          if (points[i].vertices && points[i].vertices.length && !points[i].isValueInvalid) {
            this.drawRectangle(x, y, width, height, points[i].name);
          }
        }
      }
    }
    this.loadFinish();
  }

  loadFinish() {
    formUpload.classList.add("hide");
    document.getElementById("InvoiceTable").classList.add("hide");
    document.getElementById("formResult").classList.remove("hide");
    document.getElementById("infoInvoice").classList.remove("hide");
  }

  setBackground(width, height, id) {
    const img = new Image();

    axios.get(API_URL + '/document/image?id='+id, {
        headers: {
          "Access-Control-Allow-Headers": "application/json"
        },
        auth: DocumentRecognition.getAuthObject()
    }).then(response => {
      if (response.data != null) {
        let base64Src = "data:image/png;base64," + response.data;
        img.src = base64Src;
        root.style.backgroundImage = `url(${base64Src})`;
      }
    });

    canvas.width = width;
    canvas.height = height;
    root.style.width = `${width}px`;
    root.style.height = `${height}px`;
  }

  drawRectangle(x, y, width, height, text) {
    const canvas = document.getElementById("canvas");

    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");

      ctx.fillStyle = "rgba(0, 0, 200, 0.1)";
      ctx.fillRect(x, y, width, height);
      ctx.strokeStyle = "rgb(0, 0, 200)";
      ctx.font = "10px Arial";
      ctx.strokeText(text, x, y);
      ctx.strokeRect(x, y, width, height);
    }
  }

  random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
  }
}
