import axios from "axios";
import { Canvas } from "./../classes/canvas";
import {InsertInput} from "./../classes/insert-input";
import {Loader} from "./../classes/loader";
import {API_URL, API_URL_V2} from "../environment/environment";
import { Pagination } from "../classes/pagination";


export class DocumentRecognition {

    static getParsedDocumentRequest(taskId) {
        return axios.get(`${API_URL}/document/get_task/` + taskId, {
                headers: {"Access-Control-Allow-Headers": "application/json"},
                auth: this.getAuthObject()
            });
    }

    static getScanDocumentAgainRequest(taskId) {
        return axios.get(`${API_URL}/document/scan_document_again/` + taskId, {
            headers: {"Access-Control-Allow-Headers": "application/json"},
            auth: this.getAuthObject()
        });
    }

    static scanDocumentAgainByTaskId(id) {
        Loader.show();
        this.getScanDocumentAgainRequest(id).then(response =>  {
                this.waitForDisplayProcessedDocument(response)
            }).catch(function (error) {
                alert(error);
                Loader.hide();
            })
    }

    static getDocumentByTaskId(id) {
        Loader.show();
        this.getParsedDocumentRequest(id).then(response =>  {
                this.waitForDisplayProcessedDocument(response)
            }).catch(function (error) {
            alert(error);
            Loader.hide();
        })
    }

    static displayProcessedDocument(response) {
        document.getElementById("left-side").classList.remove("s12");
        document.getElementById("left-side").classList.add("s8");
        Loader.hide();
        document.getElementById("ScanAgain").classList.remove("hide");
        new Canvas(response.data['documentEntity']);
        new InsertInput(response.data);
    }

    static canDrawDocument(response) {
        return !(response.data['documentEntity'] == null || response.data['status'] !== 'COMPLETED')
    }

    static isError(response) {
        return response.data['status'] === 'ERROR';
    }

    static displayError(response) {
        if (response.data.message != null) {
            alert(response.data.message);
        }
    }

    static waitForDisplayProcessedDocument(response) {
        if (this.canDrawDocument(response)) {
            this.displayProcessedDocument(response);
        } else if (DocumentRecognition.isError(response)) {
            DocumentRecognition.displayError(response);
            Loader.hide()
        } else {
            let intervalHandler = setInterval(function() {
                DocumentRecognition.getParsedDocumentRequest(response.data['taskId']).then(response => {
                    if (response.status !== 200) {
                        clearInterval(intervalHandler);
                    } else {
                        if (DocumentRecognition.canDrawDocument(response)) {
                            DocumentRecognition.displayProcessedDocument(response);
                            clearInterval(intervalHandler);
                        } else if (DocumentRecognition.isError(response)) {
                            DocumentRecognition.displayError(response);
                            Loader.hide();
                            clearInterval(intervalHandler);
                        }
                    }
                })
            }, 1000);
        }
    }

  static uploadImg(data) {
      Loader.show();
      axios.post(`${API_URL}/document/uploadimage`, data, {
        headers: {
          "Access-Control-Allow-Headers": "application/json",
          "Content-Type": "multipart/form-data"
        },
          auth: this.getAuthObject()
      }).then(response => {
          this.waitForDisplayProcessedDocument(response)
      }).catch(function (error) {
        // handle error
        alert(error);
        Loader.hide();
    })
  }

    static getAuthObject() {
        if (!localStorage.hasOwnProperty("l") || !localStorage.hasOwnProperty("p")) {
            return null;
        }

        return {
            username: this.b64DecodeUnicode(localStorage.getItem("l")),
            password: this.b64DecodeUnicode(localStorage.getItem("p"))
        }
    }

    static b64EncodeUnicode(str) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
    }

    static b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    static login(username, password) {
        axios.get(`${API_URL}/user/login`, {
                headers: {
                    "Access-Control-Allow-Headers": "application/json"
                },
                auth: {
                    username: username,
                    password: password
                }
            }).then(response => {
                localStorage.setItem("l", this.b64EncodeUnicode(username));
                localStorage.setItem("p", this.b64EncodeUnicode(password));

                document.getElementById("InvoiceLogin").style.display = "none";
                document.getElementById("nav-mobile").classList.remove("hide");
                document.getElementById("left-side").classList.remove("s12");
                document.getElementById("left-side").classList.add("s8");
                document.getElementById("left-side").style.display = "block";
                document.getElementById("right-side").style.display = "block";
            }, error => {
                document.getElementById("InvoiceLogin").classList.remove("hide");
                let response_text = document.getElementById("bad-response-text");
                response_text.innerText = "Niepoprawny login lub hasło!";
                response_text.style.color = "red";
                response_text.style.fontWeight = "bold";

            if (localStorage.hasOwnProperty("l")) {
                localStorage.removeItem("l");
            }

            if (localStorage.hasOwnProperty("p")) {
                localStorage.removeItem("p");
            }
        });
    }

    static getDocumentList(pageNumber = 1, perPage = 20, searchQuery = null) {
        if(DocumentRecognition.currentQuery || searchQuery) {
            document.getElementById('ClearSearch').classList.remove('hidden');
        }

        document.getElementById('ClearSearch').onclick = () => {
            DocumentRecognition.currentQuery = undefined;
            DocumentRecognition.getDocumentList();
            document.getElementById('ClearSearch').classList.add('hidden');
        };

        DocumentRecognition.currentPage = pageNumber;
        if(searchQuery) {
            DocumentRecognition.currentQuery = searchQuery;
        }

        document.getElementById("left-side").classList.remove("s8");
        document.getElementById("left-side").classList.add("s12");

        const searchFormEl = document.querySelector('#TableSearchForm');
        if(DocumentRecognition.currentQuery) {
            const inputEl = searchFormEl.querySelector('#TableSearch');
            inputEl.value = DocumentRecognition.currentQuery;
        }
        
        searchFormEl.onsubmit = (e) => {
            e.preventDefault();
            const inputEl = searchFormEl.querySelector('#TableSearch');
            const searchQuery = inputEl?.value;
            DocumentRecognition.getDocumentList(1, perPage, searchQuery);
            // searchFormEl.reset();
        }

        const urlWithSearch = `${API_URL_V2}/invoices?page=${pageNumber}&perPage=${perPage}&q=${DocumentRecognition.currentQuery}`;
        const urlWithoutSearch = `${API_URL_V2}/invoices?page=${pageNumber}&perPage=${perPage}`;

        axios.get(DocumentRecognition.currentQuery ? urlWithSearch : urlWithoutSearch, {
                headers: {
                    "Access-Control-Allow-Headers": "application/json"
                },
                auth: this.getAuthObject()
            })
            .then(response =>  {
                let i;
                let objectList = response.data["data"];
                let tbody = document.getElementById('InvoiceTableBody');
                tbody.innerHTML = "";
                for (i = 0; i < objectList.length; i++) {
                    let taskEntity = objectList[i];
                    let documentEntity = taskEntity['documentEntity'];

                    let tr = document.createElement("tr");
                    let tdId = document.createElement("td");
                    tdId.innerHTML = taskEntity.taskId;
                    tr.appendChild(tdId);

                    let tdStatus = document.createElement("td");
                    tdStatus.innerHTML = taskEntity.status;
                    tr.appendChild(tdStatus);

                    let tdSeller = document.createElement("td");
                    if (documentEntity.sellerData != null) {
                        tdSeller.innerHTML = `${documentEntity.sellerData.name}<br>NIP: ${documentEntity.sellerData.nip}`;
                    }
                    tr.appendChild(tdSeller);

                    let tdBuyer = document.createElement("td");
                    if (documentEntity.buyerData != null) {
                        tdBuyer.innerHTML = `${documentEntity.buyerData.name}<br>NIP: ${documentEntity.buyerData.nip}`;
                    }
                    tr.appendChild(tdBuyer);

                    let tdBrutto = document.createElement("td");
                    tdBrutto.innerHTML = documentEntity.brutto || null;
                    tr.appendChild(tdBrutto);

                    let tdNetto = document.createElement("td");
                    tdNetto.innerHTML = documentEntity.netto || null;
                    tr.appendChild(tdNetto);

                    let tdVat = document.createElement("td");
                    tdVat.innerHTML = documentEntity.vat || null;
                    tr.appendChild(tdVat);

                    let id = taskEntity.taskId;
                    let tdPreview = document.createElement("td");
                    if (taskEntity.status === "COMPLETED") {
                        tdPreview.innerHTML= "<a href='#' style='cursor:pointer'>Podglad</a>";
                        tdPreview.onclick = () => DocumentRecognition.getDocumentByTaskId(id);
                    } else {
                        tdPreview.innerHTML = "<a href='#' style='cursor:pointer'>Skanuj ponownie</a>";
                        tdPreview.onclick = () => DocumentRecognition.scanDocumentAgainByTaskId(id);
                    }

                    tr.appendChild(tdPreview);
                    tbody.appendChild(tr);
                };

                const pagination = new Pagination(document.querySelector('#InvoiceTablePagination'), response.data["pages"]);
                pagination.setCallback(DocumentRecognition.getDocumentList.bind(this));
                pagination.setActive(pageNumber);
                pagination.render();
                window.scrollTo(0, 0);
            }).catch(function (error) {
                // handle error
                // alert(error);
                console.log(error)
             });
    }
}
