import { DocumentRecognition } from "./services/documentRecognition";

document.addEventListener(
  "DOMContentLoaded",
  function() {

      let modal = document.getElementById("modal-msg");
      window.onclick = function(event) {
          if (event.target === modal) {
              modal.style.display = "none";
          }
      };

    document.getElementById("modal-msg-close").onclick = () => modal.style.display = "none";
    document.getElementById("UploadInput").onclick = () => {
        const data = new FormData();
        data.append("file", document.getElementById("file").files[0]);
        DocumentRecognition.uploadImg(data);
    };

      let authObj = DocumentRecognition.getAuthObject();
      if (authObj != null) {
            DocumentRecognition.login(authObj.username, authObj.password);
      } else {
          document.getElementById("InvoiceLogin").classList.remove("hide");
      }

      document.getElementById("login-button").onclick = () => {
          DocumentRecognition.login(
              document.getElementById("user-login").value,
              document.getElementById("user-password").value
          );
      };

      document.getElementById("InvoiceList").onclick = () => {
          DocumentRecognition.getDocumentList(DocumentRecognition.currentPage);
          document.getElementById("InvoiceTable").classList.remove("hide");
          document.getElementById("formUpload").classList.add("hide");
          document.getElementById("formResult").classList.add("hide");
          document.getElementById("infoInvoice").classList.add("hide");
          document.getElementById("ScanAgain").classList.add("hide");
      };

      document.getElementById("InvoiceUpload").onclick = () => {
          document.getElementById("formUpload").classList.remove("hide");
          document.getElementById("InvoiceTable").classList.add("hide");
          document.getElementById("formResult").classList.add("hide");
          document.getElementById("infoInvoice").classList.add("hide");
          document.getElementById("ScanAgain").classList.add("hide");
          document.getElementById("left-side").classList.remove("s12");
        document.getElementById("left-side").classList.add("s8");   
      };
  },
  false
);
