export class Pagination {
  constructor(el, pages) {
    el.innerHTML = '';
    this.rootElement = el;
    this.pages = pages;
    this.maxPages = 10;
  }

  setActive(page) {
    this.activePage = page;
  }

  setCallback(callback) {
    this.callback = callback;
  }

  prev() {
    if(this.activePage > 1) {
      this.callback(this.activePage - 1);
    }
  }

  next() {
    if(this.activePage < this.pages) {
      this.callback(this.activePage + 1);
    }
  }

  goTo(page = 1) {
    if(page <= this.pages && page >= 1) {
      this.callback(page);
    }
  }

  render() {
    const halfMaxPages = Math.round(this.maxPages/2);
    let renderFromPage = this.activePage - halfMaxPages > 0 ? this.activePage - halfMaxPages : 1;
    let maxRenderPages = (this.activePage + halfMaxPages) < this.pages ? (this.activePage + halfMaxPages) : this.pages;

    if(maxRenderPages - renderFromPage < this.maxPages) {
      if((this.activePage + halfMaxPages) < this.pages) {
        maxRenderPages += this.maxPages - (maxRenderPages - renderFromPage) - 1;
      } else {
        renderFromPage -= this.maxPages - (maxRenderPages - renderFromPage);
      }
    }

    if(renderFromPage < 1) renderFromPage = 1;

    if(this.activePage > 1) {
      this.rootElement.appendChild(this.createButton('<', this.prev.bind(this)));
    }

    if(this.activePage > halfMaxPages+1) {
      this.rootElement.appendChild(this.createButton(1, this.goTo.bind(this, 1), false));
      this.rootElement.appendChild(this.createDivider());
    }
    
    for(let i = renderFromPage; i <= maxRenderPages; i++) {
      this.rootElement.appendChild(this.createButton(i, this.goTo.bind(this, i), i == this.activePage));
    }

    if(this.activePage + halfMaxPages < this.pages) {
      this.rootElement.appendChild(this.createDivider());
      this.rootElement.appendChild(this.createButton(this.pages, this.goTo.bind(this, this.pages), false));
    }
    
    if(this.activePage != this.pages) {
      this.rootElement.appendChild(this.createButton('>', this.next.bind(this)));
    }
  }

  createDivider() {
    const dividerEl = document.createElement('span')
    dividerEl.classList.add('app-pagination-divider');
    dividerEl.innerText = '...';
    return dividerEl;
  }

  createButton(inner, callback, isActive = false) {
    const button = document.createElement('button');
    button.classList.add('app-pagination-button');
    if(isActive) button.classList.add('active');
    button.setAttribute('pageIndex', inner);
    button.innerHTML = inner;
    button.onclick = () => callback();
    return button;
  }
}