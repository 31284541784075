import {DocumentRecognition} from "../services/documentRecognition";

const infoInvoice = document.getElementById('infoInvoice');
let infoInvoiceFull = null;
export class InsertInput {


  constructor(response) {
    infoInvoice.innerHTML="";
    let data = response['documentEntity'];
    data.finalModelEntityList.forEach((elementData)=> this.privateCreateElement(elementData, false));
    if(data.sellerData != undefined){
      this.privateCreateElementForCompany(data.sellerData, "Sprzedawca");
    }
    if(data.buyerData != undefined){
      this.privateCreateElementForCompany(data.buyerData, "Nabywca");
    }
    infoInvoice.innerHTML+='<br/><br/><div style=\"padding-top: 150px;  \"  id="infoInvoiceFull" class=\" hide\"></div>';
    infoInvoiceFull = document.getElementById('infoInvoiceFull');
    data.modelEntityList.forEach((elementData) => this.privateCreateElement(elementData,true));
    document.getElementById("ScanAgain").onclick = () => DocumentRecognition.scanDocumentAgainByTaskId(response.taskId);

    infoInvoice.innerHTML += "<button id='showEnitiesButton' onclick='document.getElementById(\"infoInvoiceFull\").classList.remove(\"hide\"); '>Pokaż wszystkie encje</button>";
  }


  privateCreateElement(elementData, full) {
    const divRow = document.createElement('div');
    const div = document.createElement('div');
    const input = document.createElement('input');
    const label = document.createElement('label');

    div.setAttribute('class', 'input-field col s6');
    input.setAttribute('value',elementData.value);
    input.setAttribute('id',elementData.value);
    input.setAttribute('type','text');
    input.setAttribute('class','validate');

    if (full) {
      input.classList.add('blue-text');
    }
    label.classList.add('active');

    if (elementData.isValueInvalid) {
      input.classList.add('background-wrong')
    }

    label.innerText += elementData.name;
    div.appendChild(input);
    div.appendChild(label);
    divRow.appendChild(div);

    if (full) {
      divRow.setAttribute("class","full");
      infoInvoiceFull.appendChild(divRow);
    } else {
      infoInvoice.appendChild(divRow);
    }
  }

  privateCreateElementForCompany(elementData, entityName){
    console.error(elementData)

    let value = "Nie znaleziono informacji";

    if(elementData.hasOwnProperty("name")){
        value = elementData.name;

      value += " "+elementData.postcode+" "+elementData.city+ " "+elementData.street+" "+elementData.buildingNumber;
      if(elementData.localNumber != null){
        value += "/"+elementData.localNumber
      }
    }

    const divRow = document.createElement('div');
    const div = document.createElement('div');
    const input = document.createElement('textarea');
    const label = document.createElement('label');

    div.setAttribute('class', 'input-field col s6');
    input.setAttribute('type','text');
    input.setAttribute('class','validate');
    input.innerHTML=value
    label.setAttribute('class','active');
    label.innerText += entityName;
    infoInvoice.appendChild(divRow);

    divRow.appendChild(div);
    div.appendChild(input);
    div.appendChild(label);

  }
}
